import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class SlugNameStore {
	public SLUGNAME = '';

	/**
	 * Purpose: Store slugName to Global store
	 * Created By: Arjun Jayakumar
	 * Created On: 06-11-2024
	 */
	public setSlugName(slugName: string): void {
		if (slugName.trim()) this.SLUGNAME = slugName.trim();
	}

	public fetchDynamicSlugName(): string {
		window.localStorage.removeItem('slugName');
		const slugNameFromRoute = window.location.pathname.split('/')[1];
		let slugName = '';

		if (
			window.location.hostname != 'localhost' &&
			window.location.hostname != '127.0.0.1' &&
			window.location.hostname != '192.168.18.7' &&
			window.location.hostname != '192.168.0.130' &&
			window.location.hostname != '207.38.85.230' &&
			window.location.hostname != '178.63.193.224' &&
			window.location.hostname != '178.63.193.226' &&
			window.location.hostname != '178.63.193.225' &&
			window.location.hostname != '45.66.245.224'
		) {
			let array = window.location.hostname.split('.');

			if (array[0] == 'www' || array[0] == 'admin') {
				slugName = array[1].replace('admin-', '');
			} else {
				slugName = array[0].replace('admin-', '');
			}
		} else if (slugNameFromRoute && slugNameFromRoute !== 'admin') {
			slugName = slugNameFromRoute;
		} else if (this.SLUGNAME && this.SLUGNAME !== 'admin') {
			slugName = this.SLUGNAME;
		} else {
			slugName = 'null';
		}

		this.setSlugName(slugName);
		return slugName;
	}
}
