import { EventEmitter, inject, Injectable, Output } from '@angular/core';
import { ApiService } from './api.service';
import * as moment from 'moment';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SlugNameStore } from '../slugName.store';
import { ActivatedRoute } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class GeneralService {
	/**Business slug name */
	public slugName: string;

	@Output() businessDetailsEmitter: EventEmitter<any> = new EventEmitter();

	@Output() emitAppointmentName: EventEmitter<any> = new EventEmitter();

	@Output() onClickAddBookingFromNotification: EventEmitter<any> = new EventEmitter();

	@Output() reminderDetailsEmitter: EventEmitter<any> = new EventEmitter();

	@Output() setPreventUserFromNavigateToFalseEmitter: EventEmitter<any> = new EventEmitter();

	constructor(private apiService: ApiService, private slugNameStore: SlugNameStore, private route: ActivatedRoute) {
		this.slugName = this.slugNameStore.fetchDynamicSlugName();
	}

	/**
	 * *Custom validation for password match
	 * @param controlName
	 * @param matchingControlName
	 */
	passwordMatchCheck(controlName: string, matchingControlName: string) {
		return (formGroup: UntypedFormGroup) => {
			const control = formGroup.controls[controlName];
			const matchingControl = formGroup.controls[matchingControlName];

			if (matchingControl.errors && !matchingControl.errors.mustMatch) {
				// return if another validator has already found an error on the matchingControl
				return;
			}

			// set error on matchingControl if validation fails
			if (control.value !== matchingControl.value) {
				matchingControl.setErrors({ mustMatch: true });
			} else {
				matchingControl.setErrors(null);
			}
		};
	}

	/**
	 * Function to set business slug name
	 * @param slugName business slug name
	 */
	setSlugName(slugName: string): void {
		this.slugName = slugName;
	}

	/**
	 * Function to return the subdomain name.
	 *
	 * !DEPRICATED - DON'T USE THIS FUNCTION
	 * ? This function is not to used anywhere in the codebase.
	 * ? Instead use the slugNameStore.fetchDynamicSlugName()
	 *
	 * TODO: Keep it for reference purposes only
	 */
	getSubDomain() {
		/**
		 * ! Do not remove
		 * */
		let slugName = '';
		const hostName = window.location.hostname;
		const slugFromParam = this.route.snapshot.paramMap.get('slugName');

		if (hostName != 'localhost' && hostName != '192.168.18.7' && hostName != '207.38.85.230' && hostName != '178.63.193.224' && hostName != '178.63.193.226' && hostName != '178.63.193.225' && hostName != '45.66.245.224') {
			let array = hostName.split('.');
			if (array[0] == 'www') {
				slugName = array[1];
			} else {
				slugName = array[0];
			}

			if (slugName !== window.localStorage.getItem('slugName') || slugName !== this.slugName) {
				window.localStorage.setItem('slugName', slugName);
				this.slugNameStore.setSlugName(slugName);
			}
		} else if (slugFromParam) {
			slugName = slugFromParam;
			if (slugFromParam !== window.localStorage.getItem('slugName') || slugFromParam !== this.slugName) {
				window.localStorage.setItem('slugName', slugFromParam);
				this.slugNameStore.setSlugName(slugFromParam);
			}
		} else if (this.slugName) {
			slugName = this.slugName;
		} else {
			slugName = environment.slugName;
		}

		return slugName;

		/**
		 * ! Do not remove
		 * */
	}

	/**
	 * Function to validate subdomain.
	 */
	validateSubdomain() {
		const url = '/public/validate/slugname?sub_domain=' + this.slugNameStore.fetchDynamicSlugName();
		return this.apiService.get(url);
	}

	/**
	 *
	 * @param utcString
	 * @param timezone
	 */
	timeConvertor(utcString, timezone) {
		// let localUtc = moment().utcOffset();
		// localUtc = 0 - localUtc;
		// let time = moment(utcString).utcOffset(localUtc);
		// console.log(time['_d'])
		// console.log(new Date().getTimezoneOffset());
		// let time1 = moment(time['_d']).utcOffset(timezone.offset);
		// // let time1 = moment(time['_d']).utcOffset('-06:00');
		// console.log(time1)
		// return time1['_d']
		return new Date(utcString);
	}

	/**
	 *
	 * @param type Monthly,Weekly,Daily
	 * @param hidePast
	 * @param datePickerDate
	 */
	getFirstAndLastDate(type: 'Weekly' | 'Monthly' | 'Daily', hidePast: boolean, datePickerDate) {
		const obj = {
			firstDate: null,
			lastDate: null,
		};

		if (type == 'Monthly') {
			let startDate;
			let endDate;
			if (hidePast) {
				startDate = moment();
				obj.firstDate = startDate['_d'];
			} else {
				startDate = moment().startOf('month');
				obj.firstDate = startDate['_d'];
			}
			endDate = moment().endOf('month');
			obj.lastDate = endDate['_d'];
		} else if (type == 'Weekly') {
			let startDate;
			let endDate;
			if (hidePast) {
				startDate = moment();
				obj.firstDate = startDate['_d'];
			} else {
				startDate = moment().startOf('week');
				obj.firstDate = startDate['_d'];
			}
			endDate = moment().endOf('week');
			obj.lastDate = endDate['_d'];
		} else if (type == 'Daily') {
			let startOfDay;
			let endOfDay;
			if (datePickerDate) {
				startOfDay = moment(datePickerDate);
				endOfDay = moment(datePickerDate);
			} else {
				startOfDay = moment();
				endOfDay = moment();
			}
			obj.firstDate = startOfDay['_d'];
			obj.lastDate = endOfDay['_d'];
		}
		console.log(obj);
		return obj;
	}

	checkImage(imageSrc, good, bad) {
		var img = new Image();
		img.onload = good;
		img.onerror = bad;
		img.src = imageSrc;
	}

	/*Date:10/03/2021
    Created:Rahul Prasad
    Purpose:Function to check gender*/
	checkGender(gender) {
		if (gender) {
			if (gender == 'Male') {
				const url = 'assets/images/male.jpg';
				return url;
			} else if (gender == 'Female') {
				const url = 'assets/images/female.jpg';
				return url;
			} else {
				const url = 'assets/images/male.jpg';
				return url;
			}
		} else {
			const url = 'assets/images/male.jpg';
			return url;
		}
	}

	/*Date:26/03/2021
  Task id:16805
  Created:Rahul Prasad
  Purpose:Function to list country and country code*/
	getCountryList() {
		const url = '/admin/list/countryCodes';
		return this.apiService.get(url);
	}

	getStates(countryName) {
		const apiUrl = `/superadmin/list-countries/${countryName}`;
		return this.apiService.get(apiUrl);
	}

	/**
	 * Date:01/06/2021
	 * Created:Rahul Prasad
	 * Function to mask customer phone number
	 */
	maskCustomerPhoneNumber(phoneNumber) {
		if (phoneNumber) {
			const number = phoneNumber;
			const firstTwoNumber = number.substring(0, 2);
			const lastTwoNumber = number.slice(-2);
			const maskingLength = number.length - 4;
			let hidedNumber = '';
			for (let i = 0; i < maskingLength; i++) {
				hidedNumber = hidedNumber + 'X';
			}
			const maskedNumber = firstTwoNumber + hidedNumber + lastTwoNumber;
			return maskedNumber;
		} else {
			return '';
		}
	}

	/**
	 *
	 */
	windowResizeEvent() {
		setTimeout((_) => {
			window.dispatchEvent(new Event('resize'));
		}, 100);
	}

	/**
	 * Created By Sree chand
	 * 01 Oct 2021
	 * Function to convert data url to blob
	 * @param dataURI data url
	 */
	dataURItoFile(dataURI: any, name: string, type: string) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		var byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
		else byteString = unescape(dataURI.split(',')[1]);

		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		var ia = new Uint8Array(byteString.length);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		const blob = new Blob([ia], { type: mimeString });
		const fileOfBlob = new File([blob], name, {
			type: type,
			lastModified: new Date().getTime(),
		});
		return fileOfBlob;
		return blob;
	}

	/**
	 *
	 * @param file
	 * @returns
	 */
	isFileDocument(file: any) {
		const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
		return file && acceptedImageTypes.includes(file['type']);
	}

	isFileImage(file: any) {
		const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
		return file && acceptedImageTypes.includes(file['type']);
	}

	/**
	 * Purpose: To fetch data for Insta register form
	 * Created By: Arjun Jayakumar
	 * Created On: 22-03-2024
	 */
	public getInstaRegMasterData(): Observable<any> {
		return this.apiService.get('/customer/booking/getRegMasterData');
	}
}
