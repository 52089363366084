import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApiService } from './api.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { SlugNameStore } from 'src/app/slugName.store';
import { ActivatedRoute } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class GeneralService {
	/**Business slug name */
	public slugName: string;

	/**Phone number regex  */
	public phoneNumberRegex = /^(\+)?([ 0-9]){10,16}$/g;

	noCanServeUser: Boolean = false; //Boolean varible to recoganize if more than one canserve user is present or not

	constructor(private apiService: ApiService, private slugNameStore: SlugNameStore, private route: ActivatedRoute) {
		this.slugName = this.slugNameStore.fetchDynamicSlugName();
	}

	@Output() refreshUserList: EventEmitter<any> = new EventEmitter();

	@Output() disableScrollEmitter: EventEmitter<any> = new EventEmitter();

	@Output() enableScrollEmitter: EventEmitter<any> = new EventEmitter();

	@Output() enableImagePermissionEmitter: EventEmitter<any> = new EventEmitter();

	@Output() disableImagePermissionEmitter: EventEmitter<any> = new EventEmitter();

	@Output() billingSettingsUpdatedEmitter: EventEmitter<any> = new EventEmitter();

	@Output() refreshAppointmentListEmitter: EventEmitter<any> = new EventEmitter();

	@Output() refreshUserListEmitter: EventEmitter<any> = new EventEmitter();

	@Output() refreshBackgroundDataEmitter: EventEmitter<any> = new EventEmitter();

	@Output() timezoneUpdatedEmitter: EventEmitter<any> = new EventEmitter();

	/**
	 *
	 */
	windowResizeEvent() {
		setTimeout((_) => {
			window.dispatchEvent(new Event('resize'));
		}, 100);
	}

	/**
	 * Function to set business slug name
	 * @param slugName business slug name
	 */
	setSlugName(slugName) {
		this.slugName = slugName;
	}

	/**
	 * Function to return the subdomain name.
	 *
	 * !DEPRICATED - DON'T USE THIS FUNCTION
	 * ? This function is not to used anywhere in the codebase.
	 * ? Instead use the slugNameStore.fetchDynamicSlugName()
	 *
	 * TODO: Keep it for reference purposes only
	 */
	getSubDomain() {
		/**
		 * ! Do not remove
		 * */
		let slugName = '';
		const hostName = window.location.hostname;
		const slugFromParam = this.route.snapshot.paramMap.get('slugName');
		console.clear();
		console.log('SlugNameFromParams', slugFromParam);

		if (
			hostName != 'localhost' &&
			hostName != '127.0.0.1' &&
			hostName != '192.168.18.7' &&
			hostName != '192.168.0.130' &&
			hostName != '207.38.85.230' &&
			hostName != '178.63.193.224' &&
			hostName != '178.63.193.226' &&
			hostName != '178.63.193.225' &&
			hostName != '45.66.245.224'
		) {
			let array = hostName.split('.');

			if (array[0] == 'www' || array[0] == 'admin') {
				slugName = array[1].replace('admin-', '');
			} else {
				slugName = array[0].replace('admin-', '');
			}

			if (slugName !== window.localStorage.getItem('slugName') || slugName !== this.slugName) {
				window.localStorage.setItem('slugName', slugName);
				this.slugNameStore.setSlugName(slugName);
			}
		} else if (slugFromParam) {
			slugName = slugFromParam;
			if (slugFromParam !== window.localStorage.getItem('slugName') || slugFromParam !== this.slugName) {
				window.localStorage.setItem('slugName', slugFromParam);
				this.slugNameStore.setSlugName(slugFromParam);
			}
		} else if (this.slugName) {
			slugName = this.slugName;
		} else {
			slugName = environment.slugName;
		}

		return slugName;
		/**
		 * ! Do not remove
		 * */

		// let slugName = '';

		// if (localStorage.getItem('slugName')) {
		//   slugName = localStorage.getItem('slugName')
		// } else if (this.slugName) {
		//   slugName = this.slugName
		// } else {
		//   slugName = null;
		// }

		// return slugName;
	}

	/**
	 * Function to validate subdomain.
	 */
	validateSubdomain() {
		const url = '/public/validate/slugname?sub_domain=' + this.slugNameStore.fetchDynamicSlugName();
		return this.apiService.get(url);
	}

	/**
	 *
	 * @param utcString
	 * @param timezone
	 */
	timeConvertor(utcString, timezone) {
		// let localUtc = moment().utcOffset();
		// localUtc = 0 - localUtc;
		// let time = moment(utcString).utcOffset(localUtc);

		// let time1 = moment(time['_d']).utcOffset(timezone.offset);
		// // let time1 = moment(time['_d']).utcOffset('-06:00');

		// return time1['_d']
		return new Date(utcString);
	}

	/**
	 *
	 * @param type Monthly,Weekly,Daily
	 * @param hidePast
	 * @param datePickerDate
	 */
	getFirstAndLastDate(type: 'Weekly' | 'Monthly' | 'Daily', hidePast: boolean, datePickerDate) {
		const obj = {
			firstDate: null,
			lastDate: null,
		};

		if (type == 'Monthly') {
			let startDate;
			let endDate;
			if (hidePast) {
				startDate = moment();
				obj.firstDate = startDate['_d'];
			} else {
				startDate = moment().startOf('month');
				obj.firstDate = startDate['_d'];
			}
			endDate = moment().endOf('month');
			obj.lastDate = endDate['_d'];
		} else if (type == 'Weekly') {
			let startDate;
			let endDate;
			if (hidePast) {
				startDate = moment();
				obj.firstDate = startDate['_d'];
			} else {
				startDate = moment().startOf('week');
				obj.firstDate = startDate['_d'];
			}
			endDate = moment().endOf('week');
			obj.lastDate = endDate['_d'];
		} else if (type == 'Daily') {
			let startOfDay;
			let endOfDay;
			if (datePickerDate) {
				startOfDay = moment(datePickerDate);
				endOfDay = moment(datePickerDate);
			} else {
				startOfDay = moment();
				endOfDay = moment();
			}
			obj.firstDate = startOfDay['_d'];
			obj.lastDate = endOfDay['_d'];
		}

		return obj;
	}

	checkImage(imageSrc, good, bad) {
		var img = new Image();
		img.onload = good;
		img.onerror = bad;
		img.src = imageSrc;
	}

	/**
	 *
	 * @param pattern
	 * @param text
	 */
	public kmpSearch(pattern, text) {
		if (pattern.length == 0) return 0; // Immediate match

		// Compute longest suffix-prefix table
		let lsp = [0]; // Base case
		for (let i = 1; i < pattern.length; i++) {
			let j = lsp[i - 1]; // Start by assuming we're extending the previous LSP
			while (j > 0 && pattern.charAt(i) != pattern.charAt(j)) j = lsp[j - 1];
			if (pattern.charAt(i) == pattern.charAt(j)) j++;
			lsp.push(j);
		}

		// Walk through text string
		let j = 0; // Number of chars matched in pattern
		for (let i = 0; i < text.length; i++) {
			while (j > 0 && text.charAt(i) != pattern.charAt(j)) j = lsp[j - 1]; // Fall back in the pattern
			if (text.charAt(i) == pattern.charAt(j)) {
				j++; // Next char matched, increment position
				if (j == pattern.length) return i - (j - 1);
			}
		}
		return -1; // Not found
	}

	/*Date:10/03/2021
  Created:Rahul Prasad
  Purpose:Function to check gender*/
	checkGender(gender) {
		if (gender) {
			if (gender == 'Male') {
				const url = 'assets/images/male.jpg';
				return url;
			} else if (gender == 'Female') {
				const url = 'assets/images/female.jpg';
				return url;
			} else {
				const url = 'assets/images/male.jpg';
				return url;
			}
		} else {
			const url = 'assets/images/male.jpg';
			return url;
		}
	}

	/*Date:26/03/2021
  Task id:16805
  Created:Rahul Prasad
  Purpose:Function to list country and country code*/
	getCountryList() {
		const url = '/admin/list/countryCodes';
		return this.apiService.get(url);
	}

	/**
	 * Function to scroll modal body to top
	 */
	scrollModalBodyToTop() {
		let modalBody = document.querySelectorAll('.modal-body');
		modalBody.forEach((modal) => {
			modal.scrollTo(0, 0);
		});
	}

	/**
	 * Date:01/06/2021
	 * Created:Rahul Prasad
	 * Function to mask customer phone number
	 */
	maskCustomerPhoneNumber(phoneNumber) {
		if (phoneNumber) {
			const number = phoneNumber;
			const firstTwoNumber = number.substring(0, 2);
			const lastTwoNumber = number.slice(-2);
			const maskingLength = number.length - 4;
			let hidedNumber = '';
			for (let i = 0; i < maskingLength; i++) {
				hidedNumber = hidedNumber + 'X';
			}
			const maskedNumber = firstTwoNumber + hidedNumber + lastTwoNumber;
			return maskedNumber;
		} else {
			return '';
		}
	}

	/*Date:16/07/2021
  Created:Rahul Prasad
  Purpose:Function to list states*/
	public listStates(countryName) {
		const apiUrl = '/superadmin/list-countries/' + countryName;
		return this.apiService.get(apiUrl);
	}

	/**
	 * Created By Sree chand
	 * 01 Oct 2021
	 * Function to convert data url to blob
	 * @param dataURI data url
	 */
	dataURItoFile(dataURI: any, name: string, type: string) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		var byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
		else byteString = unescape(dataURI.split(',')[1]);

		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to a typed array
		var ia = new Uint8Array(byteString.length);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		const blob = new Blob([ia], { type: mimeString });
		const fileOfBlob = new File([blob], name, {
			type: type,
			lastModified: new Date().getTime(),
		});
		return fileOfBlob;
		return blob;
	}

	isFileDocument(file: any) {
		const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
		return file && acceptedImageTypes.includes(file['type']);
	}

	isFileImage(file: any) {
		const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
		return file && acceptedImageTypes.includes(file['type']);
	}

	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
}

export function numericOnly(event): boolean {
	if (isNaN(event.key)) {
		return false;
	} else {
		return true;
	}
}

export type listViewType = 'grid' | 'table';
