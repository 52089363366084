import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { GeneralService } from './general.service';
import { BusinessType } from '../Models/businessType.model';
import { ActivatedRoute, Router } from '@angular/router';
import { SplashScreenService } from 'src/app/services/splash-screen.service';
import { MenuService } from './menu.service';
import { DOCUMENT } from '@angular/common';
import { LoggedInUserService } from './logged-in-user.service';
import { InternetConnectionTestService } from 'src/app/services/internet-connection-test.service';
import { environment } from 'src/environments/environment';
import { SlugNameRoutingService } from 'src/app/slugNameRouting.service';
import { SlugNameStore } from 'src/app/slugName.store';

@Injectable({
	providedIn: 'root',
})
/**
 * Admin Business service
 */
export class BusinessService {
	/**12 Hour time format */
	private twelveHourTimeFormat = 'hh:mm a';

	/**24 Hour time format */
	private twentyHourTimeFormat = 'HH:mm';

	pricePlanExpired: any;

	private businessDetailSubject = new BehaviorSubject(null);
	public businessDetail = this.businessDetailSubject.asObservable();
	isInstaBusiness: boolean = false;

	constructor(
		private apiService: ApiService,
		// private apiV2Service: ApiV2Service,
		private generalService: GeneralService,
		private router: Router,
		private loader: SplashScreenService,
		private menuService: MenuService,
		private userService: LoggedInUserService,
		private internetConnectionService: InternetConnectionTestService,
		@Inject(DOCUMENT) private document,
		private slugNameRoutingService: SlugNameRoutingService,
		private route: ActivatedRoute,
		private slugNameStore: SlugNameStore
	) {
		this.isInstaBusiness = environment.isInstaBusiness;
	}

	/**
	 *
	 * @param data business detail
	 */
	setBusinessDetail(data) {
		this.businessDetailSubject.next(data);
	}

	/**
	 * Return business detail of current business
	 */
	getBusinessDetails() {
		return this.businessDetailSubject.value;
	}

	public isInsta(): Observable<boolean> {
		return of(this.businessDetailSubject.value.hasThirdPartyIntegration && this.businessDetailSubject.value.partnerName === 'Insta');
	}

	public isThirdParty(): Observable<boolean> {
		return of(this.businessDetailSubject.value.hasThirdPartyIntegration);
	}
	public isHappilee(): Observable<boolean> {
		return of(this.businessDetailSubject.value.hasThirdPartyIntegration && this.businessDetailSubject.value.partnerName === 'Happilee');
	}

	/**
	 * Function to return time zone of a business
	 */
	getTimeFormat() {
		const businessDetails = this.getBusinessDetails();
		if (businessDetails && businessDetails.timeFormat) {
			return businessDetails.timeFormat;
		} else {
			return '12';
		}
	}

	getTimeFormatPipe() {
		if (this.getTimeFormat() == '12') {
			return this.twelveHourTimeFormat;
		} else {
			return this.twentyHourTimeFormat;
		}
	}

	/**
	 * Function return type of business
	 * 1. Check if businessType is available in business settings
	 * 2. Else return null
	 * single-consultant , multiple-service-single-consultant , multiple-service-multiple-consultant , multiple-consultant
	 */
	getBusinessType(): BusinessType {
		let type: BusinessType = null;
		if (this.getBusinessDetails().businessType) {
			//1
			type = this.getBusinessDetails().businessType;
		}
		return type;
		// return 'single-consultant'
	}

	/**
	 * Function to validate subdomain.
	 */
	validateSubdomain(): any {
		console.log('validateSubdomain()');

		const url = '/public/validate/slugname?sub_domain=' + this.slugNameStore.fetchDynamicSlugName();
		const data = this.apiService.get(url);
		let promise;
		return (promise = new Promise((resolve, reject) => {
			data.subscribe(
				(res) => {
					this.loader.stop();
					if (res.status) {
						this.menuService.invalidSlugName = false;
						const isHappilee = res?.data?.objects?.apiKey && res?.data?.objects?.hasThirdPartyIntegration && res?.data?.objects?.partnerName === 'Happilee';
						if (isHappilee) {
							window.sessionStorage.setItem('x-api-key', res?.data?.objects?.apiKey);
						} else if (window.sessionStorage.getItem('x-api-key') && !isHappilee) {
							window.sessionStorage.removeItem('x-api-key');
						}
						localStorage.setItem('business_id', res['data'].objects._id);
						localStorage.setItem('planExpired', res['data'].objects['planExpired']);
						//this.pricePlanExpired = res['data'].objects['planExpired']
						this.setBusinessDetail(res['data'].objects);
						document.title = this.getBusinessDetails().name;
						if (this.getBusinessDetails().image) {
							this.document.getElementById('favicon-icons').setAttribute('href', this.getBusinessDetails().image);
						}
						resolve(res);
					} else {
						this.menuService.invalidSlugName = true;
						if (res['message'] == 'permission denied invalid user') {
							localStorage.removeItem('token_r');
							localStorage.removeItem('user');
							this.slugNameRoutingService.navigate('/admin/login');
						} else {
							this.slugNameRoutingService.navigate('/admin/404');
						}
					}
				},
				(err) => {
					this.internetConnectionService.checkInternetConnection(err);
					this.loader.stop();
				}
			);
		}));
	}

	/**
	 * Function to validate subdomain.
	 */
	validateAuthenticatedSubdomain(): Promise<any> {
		console.log('validateAuthenticatedSubdomain()');
		// const url = '/public/validate/slugname?sub_domain=' + this.slugNameStore.fetchDynamicSlugName();
		const url = `/admin/details/business/${localStorage.getItem('business_id')}?sub_domain=` + this.slugNameStore.fetchDynamicSlugName();
		const data = this.apiService.get(url);
		let promise;
		//this.loader.start()
		return (promise = new Promise((resolve, reject) => {
			data.subscribe(
				(res) => {
					this.loader.stop();
					if (res.status) {
						localStorage.setItem('business_id', res['data'].objects._id);
						localStorage.setItem('planExpired', res['data'].objects['planExpired']);
						//this.pricePlanExpired = res['data'].objects['planExpired']
						this.setBusinessDetail(res['data'].objects);
						resolve(res);
					} else {
						localStorage.removeItem('user_id');
						localStorage.removeItem('Authorization');
						this.slugNameRoutingService.navigate('/admin/404');
					}
				},
				(err) => {
					this.internetConnectionService.checkInternetConnection(err);
					this.loader.stop();
				}
			);
		}));
	}

	/**
	 * Get new customer id from server
	 */
	getCustomerId() {
		const url = '/admin/new/customer/id/' + localStorage.getItem('business_id');
		return this.apiService.get(url);
	}

	/**
	 * Get current business patient info
	 */
	getBusinessPatientInfo() {
		const url = `/customer/fields/${localStorage.getItem('business_id')}`;
		return this.apiService.get(url);
	}

	/**
	 * Function to get all business
	 */
	getAllBusiness(page, size) {
		const url = `/customer/list/business/${page}/${size}`;
		return this.apiService.get(url);
	}

	getBusinessMenuList() {
		const url = `/admin/menu-list/${localStorage.getItem('business_id')}/${1}/${100}`;
		return this.apiService.get(url);
	}

	getEmailDynamicField(): any {
		const businessDynamicField = this.getBusinessDetails().patientInfo.find((info) => {
			return info.key == 'email';
		});
		return businessDynamicField;
	}

	checkPatientInfoFields(info) {
		const isCustomerId = this.getBusinessDetails().patientInfo.find((element) => element.isCustomerId);
		if (isCustomerId && isCustomerId.status == 'ACTIVE') {
			if (info[isCustomerId.key]) {
				return info[isCustomerId.key];
			} else {
				const customerKey = this.getBusinessDetails().patientInfo.find((element) => element.key == 'customerKey');
				return [info.customerId];
			}
		} else {
			const customerKey = this.getBusinessDetails().patientInfo.find((element) => element.key == 'customerKey');
			return info[customerKey.key];
		}
	}

	/**
	 * Created By Sree chand
	 * 01 Apr 2021
	 * Function to return customer id of a business
	 */
	getCurrentCustomerId(customerData) {
		const customerPrefix = this.getBusinessDetails().customerPrefix;
		const customerInfo: any[] = this.getBusinessDetails().patientInfo;
		const isCustomerIdIndex = customerInfo.find((field) => field.isCustomerId);
		const prefix = isCustomerIdIndex && (isCustomerIdIndex.key == 'customerKey' || isCustomerIdIndex.status == 'INACTIVE') ? customerPrefix : '';
		// console.log('isCustomerIdIndex',isCustomerIdIndex,customerPrefix,isCustomerIdIndex);

		if (isCustomerIdIndex && isCustomerIdIndex.status == 'ACTIVE') {
			// console.log('here 1');

			if (customerData[isCustomerIdIndex.key]) {
				//  console.log('here 4');
				// console.log(customerData.hasOwnProperty('customerId'));
				if (this.isInstaBusiness && customerData.hasOwnProperty('customerId')) {
					return `${isCustomerIdIndex.label}${':'} ${prefix ? prefix : ''}${customerData['customerId']}`;
				}
				return `${isCustomerIdIndex.label}${':'} ${prefix ? prefix : ''}${customerData[isCustomerIdIndex.key]}`;
			} else {
				return `${isCustomerIdIndex.label}${':'} ${'N/A'}`;
			}
		} else {
			if (customerData['customerKey']) {
				return `${'Customer ID:'}${prefix ? prefix : ''}${customerData['customerKey']}`;
			} else return '';
		}
	}

	/**
	 * Function to check have permission to enter the route
	 */
	checkHavePricePlanPermission() {
		const userIsSuperAdmin = this.userService.getLoggedInUser().adminFlag ? true : false;
		this.pricePlanExpired = this.getBusinessDetails() && this.getBusinessDetails().planExpired ? true : false;
		if (userIsSuperAdmin && this.pricePlanExpired) {
			return true;
		} else if (!userIsSuperAdmin && this.pricePlanExpired) {
			return false;
		} else {
			return true;
		}
	}

	/**
	 * Created By Sree chand
	 * 12 Oct 2021
	 * Function to get available email service
	 */
	getEmailAvailableEmailServices() {
		const url = `/admin/list/email/services`;
		return this.apiService.get(url);
	}

	getAllTimeZone() {
		const url = '/admin/list/timezone';
		return this.apiService.get(url);
	}

	/**
	 * Created By Sree chand
	 * 18 Oct 2021
	 * Function to get holiday list
	 * @param page page number
	 * @param size size
	 */
	getHolidayList(page, size = 10) {
		const url = `/admin/holiday/list/${localStorage.getItem('business_id')}/${page}/${size}`;
		return this.apiService.get(url);
	}

	createOrUpdateHoliday(obj) {
		const url = `/admin/update/holidays`;
		return this.apiService.post(url, obj);
	}

	deleteHoliday(obj) {
		const url = `/admin/delete/holidays`;
		return this.apiService.post(url, obj);
	}

	/**
	 * Create tv point
	 */
	onCreateOrUpdateTvPoint(obj) {
		const url = '/admin/update/display/points';
		return this.apiService.post(url, obj);
	}

	/**
	 * List tv points
	 */
	listAllTvPoints(page, size) {
		console.log(page, size);

		const url = `/admin/list/display/points/${localStorage.getItem('business_id')}?currentPage=${page}&pageSize=${size}`;
		return this.apiService.get(url);
	}

	/**
	 * Delete tv point
	 */
	deleteTvPoint(obj) {
		const url = '/admin/delete/display/points';
		return this.apiService.post(url, obj);
	}

	/**
	 * Refresh Selected Tv Point
	 * @param obj : { reloadFlag: 1, tvPointId: selected tvPoint ID}
	 * @returns
	 */
	public refreshTvPoint(obj: any): Observable<any> {
		// return this.apiV2Service.customPathPutRequest(`/v2/app/tv/tvApp/flag`, obj);
		return this.apiService.customPathPutRequest(`/app/tv/tvApp/flag`, obj);
	}

	/**
	 * Create story
	 */
	createStory(obj) {
		const url = '/admin/create/story';
		return this.apiService.postData(url, obj);
	}

	/**
	 * Update story
	 *
	 */
	updateStory(obj) {
		const url = '/admin/update/story';
		return this.apiService.postData(url, obj);
	}

	/**
	 * List stories
	 */
	listStories(pageNo, size) {
		// const url= `/admin/story/list/${localStorage.getItem('business_id')}/${pageNo}/${size}`
		const url = `/admin/story/list/${localStorage.getItem('business_id')}/${pageNo}/${size}`;
		return this.apiService.get(url);
	}

	/**
	 * Delete story
	 */
	deleteStory(obj) {
		const url = '/admin/delete/story';
		return this.apiService.post(url, obj);
	}

	/**
	 * Change status
	 */
	changeStoryStatus(obj) {
		const url = '/admin/manage/story';
		return this.apiService.post(url, obj);
	}

	/***
	 * Function to updated reordered story list
	 */
	updateReorderedStories(obj) {
		const url = `/admin/reorder/story`;
		return this.apiService.post(url, obj);
	}

	/**
	 * Purpose: Sync Doctors API Request
	 * Created By: Arjun Jayakumar
	 * Created On: 19-01-2024
	 */
	public syncDoctors(): Observable<any> {
		// return this.apiV2Service.put('/admin/sync/doctor', {});
		return this.apiService.put('/admin/sync/doctor', {});
	}

	/**
	 * Purpose: Sync Department API Request
	 * Created By: Arjun Jayakumar
	 * Created On: 19-01-2024
	 */
	public syncDepartment(): Observable<any> {
		return this.apiService.put('/admin/sync/departments', {});
	}
}
