import { Injectable } from '@angular/core';
import { LoggedInUserService } from './logged-in-user.service';
import { Router } from '@angular/router';
import { BusinessService } from './business.service';
import { SlugNameRoutingService } from 'src/app/slugNameRouting.service';

@Injectable({
	providedIn: 'root',
})
export class MenuService {
	menus: Array<any> = [];

	isAdmin = false;
	isConsultant = false;

	invalidSlugName: Boolean = false;

	constructor(private loggedInUserService: LoggedInUserService, private router: Router, private slugNameRoutingService: SlugNameRoutingService) {
		this.loggedInUserService.loggedInUser$.subscribe((res) => {
			this.menus = res && res.menus ? res.menus : [];
			this.isAdmin = res && res.role_id && res.role_id.isAdmin ? true : false;
			this.isConsultant = res && res.role_id && res.role_id.canServe ? true : false;
		});

		// this.businessService.businessDetail.subscribe(res => {
		//   this.enableRatingAndReview = this.businessService.getBusinessDetails().allowReviewAndRating;
		// })
	}

	/**
	 * Get dashboard 1 menu
	 */
	getDashboard1Menu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'DASHBOARD-1';
		});
		if (this.isAdmin || this.isConsultant) {
			const dummyObj = {
				canCreate: true,
				canDelete: true,
				canEdit: true,
				canView: true,
			};
			return dummyObj;
			// return obj;
		} else {
			const dummyObj = {
				canCreate: true,
				canDelete: true,
				canEdit: true,
				canView: true,
			};
			return dummyObj;
		}
	}

	/**
	 * Get dashboard 2 menu
	 */
	getDashboard2Menu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'DASHBOARD-2';
		});
		// if (obj) {
		//   return obj;
		// } else {
		//   const dummyObj = {
		//     canCreate: false,
		//     canDelete: false,
		//     canEdit: false,
		//     canView: false
		//   }
		//   return dummyObj;
		// }
		if (this.isAdmin || this.isConsultant) {
			const dummyObj = {
				canCreate: true,
				canDelete: true,
				canEdit: true,
				canView: true,
			};
			return dummyObj;
			// return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	getCalenderMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'CALENDER';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return mange queue menu
	 */
	getMangeQueueMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'MANAGE QUEUE';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return Appointment menu
	 */
	getAppointmentsMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'APPOINTMENTS';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return Customers menu
	 */
	getCustomersMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'CUSTOMERS';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return Customers menu
	 */
	getInsightMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'INSIGHTS';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return Customers menu
	 */
	getProfileMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'PROFILE';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return Customers menu
	 */
	getPaymentMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'PAYMENT';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return settings menu
	 */
	getSettingsMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'SETTINGS';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return add booking menu
	 */
	getAddBookingMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'ADD BOOKING';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return bookings menu
	 */
	getManageUserMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'MANAGE USERS';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return mange department menu
	 */
	getManageDepartmentMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'MANAGE DEPARTMENTS';
		});
		//return obj;
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return mange department menu
	 */
	getManageServiceMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'MANAGE SERVICE';
		});
		//return obj;
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return mange department menu
	 */
	getManageReviewMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'REVIEW';
		});
		//return obj;
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return mange role menu
	 */
	getManageRoleMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'MANAGE ROLES';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return mange role menu
	 */
	getManageNotificationMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'MANAGE NOTIFICATIONS';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return mange role menu
	 */
	getManageQueueSettingsMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'MANAGE QUEUE SETTINGS';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return mange booking setting menu
	 */
	getManageBookingSettingsMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'MANAGE BOOKING';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return mange role menu
	 */
	getManageBusinessInfoSettingsMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'MANAGE BUSINESS INFO';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return mange role menu
	 */
	getManageBusinessSettingsMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'MANAGE BUSINESS SETTINGS';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to return mange role menu
	 */
	getBillingSettingsMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'MANAGE BILLING';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Created By Sree chand
	 * 19 Mar 2021
	 * Function to return notes menu
	 */
	getNotesMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'Notes';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to get tv app menu
	 */
	getTvAppMenu() {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'TV APP';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Function to get stories menu
	 */
	getStoriesMenu() {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'STORIES';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/**
	 * Purpose: Function to get rooms menu
	 * Created/Modified By: Arjun Jayakumar
	 * Created On: 16-01-2024
	 */
	public getRoomsMenu(): any {
		const obj = this.menus.find((me) => {
			return me.menu_id.name === 'MANAGE ROOMS';
		});
		if (obj) {
			return obj;
		} else {
			const dummyObj = {
				canCreate: false,
				canDelete: false,
				canEdit: false,
				canView: false,
			};
			return dummyObj;
		}
	}

	/** */
	redirectUserToAuthorizedRout() {
		if (this.getDashboard1Menu().canView || this.getDashboard2Menu().canView) {
			this.slugNameRoutingService.navigate('/admin/dashboard');
		} else if (this.getMangeQueueMenu().canView) {
			// this.slugNameRoutingService.navigate('/admin/manage-queue');
			this.slugNameRoutingService.navigate('/admin/manage-queue');
		} else if (this.getAppointmentsMenu().canView) {
			this.slugNameRoutingService.navigate('/admin/appointments');
		} else if (this.getCustomersMenu().canView) {
			this.slugNameRoutingService.navigate('/admin/users');
		} else if (this.getSettingsMenu().canView) {
			this.slugNameRoutingService.navigate('/admin/settings');
		}
	}

	enableRatingAndReview: boolean = false;
	checkSettingsMenuIsAvailable() {
		if (
			this.getManageUserMenu().canView ||
			this.getManageDepartmentMenu().canView ||
			this.getManageServiceMenu().canView ||
			this.getManageReviewMenu().canView ||
			this.getManageRoleMenu().canView ||
			this.getManageNotificationMenu().canView ||
			this.getManageBookingSettingsMenu().canView ||
			this.getManageBusinessSettingsMenu().canView
		) {
			return true;
		} else return false;
	}
}
