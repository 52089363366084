import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { SlugNameStore } from './slugName.store';
import { inject } from '@angular/core';

export const adminSlugNameGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const slugNameFromParam = route.paramMap.get('slugName'),
		slugNameStore = inject(SlugNameStore),
		slugNameFromRoute = window.location.pathname.split('/')[1];

	let slugNameFromSubDomain = '';

	if (
		window.location.hostname !== 'localhost' &&
		window.location.hostname !== '127.0.0.1' &&
		window.location.hostname !== '192.168.18.7' &&
		window.location.hostname !== '192.168.0.130' &&
		window.location.hostname !== '207.38.85.230' &&
		window.location.hostname !== '178.63.193.224' &&
		window.location.hostname !== '178.63.193.226' &&
		window.location.hostname !== '178.63.193.225' &&
		window.location.hostname !== '45.66.245.224'
	) {
		let array = window.location.hostname.split('.');

		if (array[0] == 'www' || array[0] == 'admin') {
			slugNameFromSubDomain = array[1].replace('admin-', '');
		} else {
			slugNameFromSubDomain = array[0].replace('admin-', '');
		}
	}

	if (slugNameFromSubDomain && (slugNameFromRoute === 'admin' || slugNameFromParam === 'admin')) {
		slugNameStore.setSlugName(slugNameFromSubDomain);
		window.location.replace(`/${slugNameFromSubDomain}/admin`);
	}
	return true;
};
