import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { filter, take, tap } from 'rxjs/operators';
import { SocialMediaService } from './Authentication/login/social-media.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { AdminThemeService } from './Admin-module/app/Services/admin-theme.service';
import { CustomerThemeService } from './services/customer-theme.service';
import { SplashScreenService } from './services/splash-screen.service';
import { GeneralService } from './services/general.service';
import { ScriptService } from './services/script-service.service';
import { InternetConnectionTestService } from './services/internet-connection-test.service';
import { ConnectionService, ConnectionState } from 'ng-connection-service';
import { SlugNameRoutingService } from './slugNameRouting.service';
import { SlugNameStore } from './slugName.store';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'YwaitCustomer';
	// keep refs to subscription to be abble to unsubscribe later
	// (NOTE: unless you want to be notified when an item is revealed by WOW,
	//  you absolutely don't need this line and related, for the library to work
	// only the call to `                                                                                 ()` at some point is necessary
	private wowSubscription: Subscription;

	account: Account;

	customerStyleUrl = '../assets/css/style.css';

	customerDeviceUrl = '../assets/css/device.css';

	customerCustomStyleUrl = '../assets/css/customerStyles.css';

	customerThemeStyle = '../assets/css/customer-theme.css';

	adminStyleUrl = '../assets/css/admin-style.css';

	adminDeviceUrl = '../assets/css/admin-device.css';

	adminCustomStyleUrl = '../assets/css/adminStyles.css';

	adminThemeStyle = '../assets/css/admin-theme.css';

	showModuleLoader = true;

	private subscription = new Subscription();

	private isOnline: boolean = false;

	constructor(
		private router: Router,
		private wowService: NgwWowService,
		private fbService: SocialMediaService,
		public sanitizer: DomSanitizer,
		private customerThemeService: CustomerThemeService,
		private loader: SplashScreenService,
		private adminThemeService: AdminThemeService,
		private generalService: GeneralService,
		private scriptService: ScriptService,
		private internetConnectionService: InternetConnectionTestService,
		private connectionService: ConnectionService,
		@Inject(DOCUMENT) private document,
		private slugNameRoutingService: SlugNameRoutingService,
		private slugNameStore: SlugNameStore
	) {
		this.subscription.add(
			this.router.events
				.pipe(
					filter((event) => event instanceof NavigationEnd),
					take(1)
				)
				.subscribe(() =>
					// Reload WoW animations when done navigating to page,
					// but you are free to call it whenever/wherever you like
					this.wowService.init({
						scrollContainer: '#scrollContainer' as any,
					})
				)
		);
		this.fbService.account.subscribe((x) => (this.account = x));
		// this.sanitizer.bypassSecurityTrustStyle;
		this.scriptService.load('pdfMake', 'vfsFonts'); // load the script file when app initialize
	}

	ngOnInit() {
		// this.wowService.init(
		//   {
		//     scrollContainer: '#scrollContainer' as any
		//   }
		// )

		/**
		 * Purpose: Handle internet connectivity checks for the user
		 * Created By: Arjun Jayakumar
		 * Created On: 23-04-2024
		 */
		this.subscription.add(
			this.connectionService
				.monitor()
				.pipe(tap((newState: ConnectionState) => (this.internetConnectionService['isConnected'] = newState.hasNetworkConnection || newState.hasInternetAccess)))
				.subscribe()
		);

		const hostName = window.location.hostname;
		// const hostName = 'admin.ywait.ywait.in';
		let array = hostName.split('.');
		if (array[0] == 'admin') {
			this.slugNameRoutingService.navigate('/admin');
		}

		// you can subscribe to WOW observable to react when an element is revealed
		this.wowSubscription = this.wowService.itemRevealed$.subscribe((item: HTMLElement) => {
			// do whatever you want with revealed element
			// console.log(item);
		});
		// this.showModuleLoader = true;
		const slugName = this.slugNameStore.fetchDynamicSlugName() ? this.slugNameStore.fetchDynamicSlugName()?.toLowerCase() : this.slugNameStore.fetchDynamicSlugName();
		const slugNameArray = ['ywait-salon', 'ywait-spa', 'ywait-single-user', 'ywait', 'ywait-homeo', 'demo', 'dental', 'dentalhospital', 'stackon'];
		const ywaitBusiness = slugNameArray.find((li) => li == slugName);
		//const slugName = 'Providence'.toLowerCase();
		if (ywaitBusiness) {
			this.showModuleLoader = true;
		} else {
			this.showModuleLoader = false;
		}

		this.dynamicStyleSheetLoader();
	}

	ngOnDestroy() {
		// unsubscribe (if necessary) to WOW observable to prevent memory leaks
		this.wowSubscription?.unsubscribe();
		this.subscription?.unsubscribe();
	}

	private prevIsAdmin: number;
	dynamicStyleSheetLoader() {
		this.subscription.add(
			this.router.events.subscribe((event) => {
				if (event instanceof NavigationStart) {
					// console.log('NavigationStart');
					if (!this.showModuleLoader) {
						this.loader.start();
					}

					if (this.internetConnectionService.checkInternetConnectionOnNavigation()) {
						this.loader.stop();
						return;
					}

					const url = event.url;
					// let hostName = 'admin-ywait.ywait.in/select-business'; //admin test
					// let hostName = 'admin-ywait.ywait.in/select-business'; //customer test
					const isAdmin = url.search('/admin');

					// const hostName = window.location.hostname; //for live/staging
					// const isAdmin = hostName.search('admin-');

					if (isAdmin != this.prevIsAdmin) {
						if (isAdmin == -1) {
							this.document.getElementById('device-style').setAttribute('href', this.customerDeviceUrl);
							this.document.getElementById('custom-style').setAttribute('href', this.customerCustomStyleUrl);
							this.document.getElementById('theme-style').setAttribute('href', this.customerThemeStyle);
							this.document.getElementById('favicon-icons').setAttribute('href', 'assets/images/Layer1000.ico');
							this.document.getElementById('main-style').setAttribute('href', this.customerStyleUrl);
						} else {
							this.document.getElementById('main-style').setAttribute('href', this.adminStyleUrl);
							this.document.getElementById('device-style').setAttribute('href', this.adminDeviceUrl);
							this.document.getElementById('custom-style').setAttribute('href', this.adminCustomStyleUrl);
							this.document.getElementById('theme-style').setAttribute('href', this.adminThemeStyle);
							this.document.getElementById('favicon-icons').setAttribute('href', 'assets/images/Ywait-app-icon-and-logo.png');
						}
					}
					this.prevIsAdmin = isAdmin;
				}

				if (event instanceof NavigationEnd) {
					// console.log('NavigationEnd');
					this.loader.stop();
					this.showModuleLoader = false;
				}

				if (event instanceof NavigationCancel) {
					// console.log('NavigationCancel');
					this.loader.stop();
					this.showModuleLoader = false;
				}
			})
		);
	}

	createElementFromHTML(htmlString) {
		var div = document.createElement('div');
		div.innerHTML = htmlString.trim();

		// Change this to div.childNodes to support multiple top-level nodes
		return div.firstChild;
	}
}

export class Account {
	id: string;
	facebookId: string;
	name: string;
	extraInfo: string;
	token?: string;
	email?: string;
}
