import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
       return new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
}

@NgModule({
       imports: [
              HttpClientModule,
              TranslateModule.forRoot({
                     loader: {
                            provide: TranslateLoader,
                            useFactory: HttpLoaderFactory,
                            deps: [HttpClient],
                     },
                     defaultLanguage: 'en', // Set default language
              }),
       ],
       exports: [TranslateModule],
})
export class SharedTranslateModule { }