<div class="container-bg-white">
    <div class="ywaiterror_page">
        <h3>{{'Oops! Page not found?'| translate }}</h3>
        <h1>404</h1>
        <h2>{{'we are sorry, the page you requested cannot be found'| translate }}</h2>
        <!-- Task id:16506 Rahul Prasad -->
        <ng-container *ngIf="!menuService.invalidSlugName">
            <h2>{{'Please click on the home button below to navigate to home'| translate }}</h2>
            <a href="javascript:void(0)" (click)="onNavigateToHome()">
                <i class="fas fa-home"></i>
            </a>
        </ng-container>
    </div>
</div>