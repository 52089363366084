import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ApiKeyInterceptorService {
	constructor() {}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const apikey = window.sessionStorage.getItem('x-api-key');
		if (apikey) {
			const clonedRequest = req.clone({
				setHeaders: {
					'x-api-key': `${apikey}`,
				},
			});
			return next.handle(clonedRequest);
		}
		return next.handle(req);
	}
}
