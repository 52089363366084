<div class="alert-box" #confirmation>
    <div class="modal-header">
        <h4 class="modal-title">{{'Unsaved changes' | translate}}</h4>
    </div>
    <div class="modal-body">
        {{'You have made changes, do you really want to go back?' | translate}}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="onConfirm()">{{ 'Yes I am sure' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="onCancel()">{{'No'| translate}}</button>
    </div>
</div>