import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomerBusinessNameAuthGuard } from './services/customer-auth.guard';
import { BusinessResolveService } from './services/business-resolve.service';
import { NotFoundPage404Component } from './Admin-module/app/not-found-page404/not-found-page404.component';
import { adminSlugNameGuard } from './adminSlugName.guard';

let routes: Routes = [];
if (window?.location?.href?.search('/admin') !== -1) {
	routes = [
		{
			path: 'admin',
			loadChildren: () => import('./Admin-module/app/app.module').then((m) => m.AppModule),
		},
		{
			path: '**',
			redirectTo: `null/404`,
			pathMatch: 'full',
		},
	];
} else {
	routes = [
		{
			path: 'country-help',
			loadChildren: () => import('./country-state-help/country-state-help.module').then((m) => m.CountryStateHelpModule),
		},
		{
			path: 'select-business',
			loadChildren: () => import('./business-selection/business-selection.module').then((m) => m.BusinessSelectionModule),
		},
		{
			path: '404',
			component: NotFoundPage404Component,
		},
		{
			path: '',
			canActivate: [CustomerBusinessNameAuthGuard],
			resolve: { businessDetails: BusinessResolveService },
			children: [
				{
					path: 'authentication',
					loadChildren: () => import('./Authentication/authentication.module').then((m) => m.AuthenticationModule),
				},
				{
					path: 'booking-ticket/:type/:bookingId',
					loadChildren: () => import('./booking-ticket/booking-ticket.module').then((m) => m.BookingTicketModule),
				},
				{
					path: '',
					loadChildren: () => import('./customer-home-layout/customer-home-layout.module').then((m) => m.CustomerHomeLayoutModule),
				},
			],
		},
		{
			path: '**',
			redirectTo: `null/404`,
			pathMatch: 'full',
		},
	];
}

const appRoutes: Routes = [
	{
		path: ':slugName',
		canActivate: [adminSlugNameGuard],
		children: routes,
	},
	{
		path: '**',
		component: NotFoundPage404Component,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
