import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SlugNameStore } from './slugName.store';

@Injectable({
	providedIn: 'root',
})
export class SlugNameRoutingService {
	constructor(private router: Router, private slugNameStore: SlugNameStore) {}

	public navigate(route: string, params: any = {}) {
		this.router.navigate([`/${this.slugNameStore.fetchDynamicSlugName()}${route}`], params);
	}
}
