import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { NgwWowModule } from 'ngx-wow';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { appInitializer } from 'src/helper/app.initializer';
import { SocialMediaService } from './Authentication/login/social-media.service';
import { fakeBackendProvider } from 'src/helper/fake-backend';
import { ModuleLoaderComponent } from './module-loader/module-loader.component';
import { SplashScreenModule } from './splash-screen/splash-screen.module';
import { NotFoundPage404Module } from './Admin-module/app/not-found-page404/not-found-page404.module';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { BookingReviewConfirmationModule } from './booking-review-confirmation/booking-review-confirmation.module';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { ConnectionServiceModule } from 'ng-connection-service';
import { ApiKeyInterceptorService } from './interceptors/api-key-interceptor.service';
import { SlugNameStore } from './slugName.store';
import { SharedTranslateModule } from './shared/translate.module';
import { TranslateService } from '@ngx-translate/core';
// import { LottieServerModule } from 'ngx-lottie/server';
export function playerFactory() {
	return player;
}
// export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
// 	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

@NgModule({
	declarations: [AppComponent, ModuleLoaderComponent],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		SocialLoginModule,
		NgwWowModule,
		ToastrModule.forRoot({
			timeOut: 5000,
			positionClass: 'toast-top-center',
			preventDuplicates: true,
		}),
		// AppleSigninModule,
		SplashScreenModule,
		NotFoundPage404Module,
		LottieModule.forRoot({ player: playerFactory }),
		LottieCacheModule.forRoot(),
		BookingReviewConfirmationModule,
		ConnectionServiceModule,
		SharedTranslateModule
		// TranslateModule.forRoot({
		// 	defaultLanguage: 'en',
		// 	loader: {
		// 		provide: TranslateLoader,
		// 		useFactory: HttpLoaderFactory,
		// 		deps: [HttpClient]
		// 	}
		// })
		//StripeModule.forRoot('pk_test_51I9V3MKqqNkOglYTIucdPpLsuvLzWSlo20kgiR9UB6aHlVF9Esmt7NDFiA0kw3mn3hk4OOo3YkNR60mdPyUmBKg600RDAsrHBX'),
		//NgxStripeModule.forRoot('pk_test_51I9V3MKqqNkOglYTIucdPpLsuvLzWSlo20kgiR9UB6aHlVF9Esmt7NDFiA0kw3mn3hk4OOo3YkNR60mdPyUmBKg600RDAsrHBX'),
		// LottieServerModule.forRoot({
		//   preloadAnimations: {
		//     folder: 'dist/browser/assets',
		//     animations: ['data.json'],
		//   },
		// })
	],
	bootstrap: [AppComponent],
	providers: [
		TranslateService,
		// { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		{ provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [SocialMediaService] },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ApiKeyInterceptorService,
			multi: true, // This is important to allow multiple interceptors
		},
		//{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		// { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		// provider used to create fake backend
		fakeBackendProvider,
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: true,
				providers: [
					// {
					//   id: AppleLoginProvider.PROVIDER_ID,
					//   provider: new AppleLoginProvider(
					//     '[CLIENT_ID]'
					//   ),
					// },
					// {
					//     id: GoogleLoginProvider.PROVIDER_ID,
					//     provider: new GoogleLoginProvider('854502397781-sie0g3oecg848gi6trl1b3olo2ttga1c.apps.googleusercontent.com')
					// },
					// {
					//     id: GoogleLoginProvider.PROVIDER_ID,
					//     provider: new GoogleLoginProvider('40761511873-mdr7gs9kk0g6j2bnb0ujaeq1svt1fj1u.apps.googleusercontent.com')
					// }, //manoj
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(environment.googleClientId),
					}, //manoj
				],
			} as SocialAuthServiceConfig,
		},
		DatePipe,
		TitleCasePipe,
		SlugNameStore,
	],
})
export class AppModule { }
